import React, {useState, useEffect, useRef} from "react";
import { readFromStorage, writeToStorage, KEYS } from "../helpers/browserStorageHandeler";

import { THEMES } from "../helpers/colors";
import { Bezier } from "bezier-js";

export default function Theme({children}) {
    const styles = useRef(THEMES);

    const [style, setStyle] = useState(null);

    useEffect(() => {
        const updateStyle = (e) => {
            setStyle(e.detail);
        };

        window.addEventListener(KEYS.STYLE, updateStyle);

        let style = readFromStorage(KEYS.STYLE);

        if (!style || !THEMES[style]) writeToStorage(KEYS.STYLE, Object.keys(styles.current)[0]);
        else setStyle(style);

        return () =>  {
            window.removeEventListener(KEYS.STYLE, updateStyle)
        }
    }, []);

    useEffect(() => {
        if (!style) return;

        const colors = styles.current[style];
        const mainElement = document.documentElement;

        mainElement.style.setProperty('--surface', colors.surface);
        mainElement.style.setProperty('--main-brand', colors.mainBrand);
        mainElement.style.setProperty('--accent', colors.accent);
        mainElement.style.setProperty('--background', colors.background);
        mainElement.style.setProperty('--success', colors.success);
        mainElement.style.setProperty('--warning', colors.warning);
        mainElement.style.setProperty('--danger', colors.danger);

        document.querySelector("meta[name=theme-color]").setAttribute('content', colors.background);
        document.getElementById('installApp').style = null;
        document.body.style = null;
        document.body.style.backgroundColor = "#000";

        function calculateCurvePoints(x1, y1, x2, y2) {
            return new Bezier([{ x: 0, y: y1 }, { x: x1, y: y1 }, { x: x2, y: y2 }, { x: 1, y: y2 }]).getLUT(10)
        }

        function toLinearGradient(points, color, position) {
            return `linear-gradient(to ${position}, ` + points.map(point => {
                const hex = Math.round(point.x * 255).toString(16);
                return `${color}${hex.length === 1 ? '0' + hex : hex} ${Math.round(point.x * 100)}%`;
            }).join(', ') + ')';
        }

        const fadePoints = calculateCurvePoints(0.46, 0, 0.58, 1);
        const fadeBottom = toLinearGradient(fadePoints, colors.background, 'bottom');

        mainElement.style.setProperty('--background-fade-bottom', fadeBottom);
    }, [style]);

    return (
        <div id='theme'>
            {children}
        </div>
    )
}