import Dexie from 'dexie';
import { debounce, throttle, DELAY_LONG } from '../functionHandeler';

const VERSION = 1.1;

class recipeDB {
    constructor() {
        this.db = new Dexie("MyRecipesDB");

        this.db.version(VERSION).stores({
            recipes: '++id, authorName, name, status, description, completionTime, quantity, image',
            recipeIngredients: '++id, recipeId, name, amount, unit',
            recipeInstructionSteps: '++id, recipeId, text',
        });

        this.STATUS = {
            TEMPORARY:'temporary',
            UPDATING:'updating',
            NOT_SYNCED:'not_synced',
            COMPLETED:'completed'
        }

        this.db.open().catch(error => {
            console.error("Failed to open database: ", error);
        });
    }

    async getRecipe(id) {
        const recipe = await this.db.recipes.get(id).catch(error => {
            console.error(`Failed to get recipe with id ${id}: `, error);
        });

        recipe.recipeIngredients = await this.getRecipeIngredients(id);
        recipe.recipeInstructionSteps = await this.getRecipeSteps(id);

        return recipe;
    }

    async getRecipes() {
        const recipes = await this.db.recipes.toArray();

        return await recipes.filter(recipe => recipe.status !== this.STATUS.TEMPORARY).map(recipe => ({
            id: recipe.id,
            name: recipe.name,
            authorName: recipe.authorName,
            description: recipe.description,
            image: recipe.image,
            language: recipe.language,
            completionTime: recipe.completionTime,
            quantity: recipe.quantity,
            location: 'LOCAL'
        }));
    }

    getTemporaryRecipe() {
        return this.db.recipes
            .where('status')
            .equals(this.STATUS.TEMPORARY)
            .first()
            .catch(error => {
                console.error("Failed to find temporary recipe: ", error);
            });    
    }

    getRecipeIngredients(recipeId) {
        return this.db.recipeIngredients
            .where('recipeId')
            .equals(recipeId)
            .toArray()
            .catch(error => {
                console.error("Failed to find ingredients of given recipe: ", error);
            });
    }

    getRecipeSteps(recipeId) {
        return this.db.recipeInstructionSteps
            .where('recipeId')
            .equals(recipeId)
            .toArray()
            .catch(error => {
                console.error("Failed to find instruction step of given recipe: ", error);
            });
    }

    async _IsNotTemporaryRecipe(id) {
        const isInvalid = (await this.getTemporaryRecipe()).id !== id;
        if (isInvalid) console.error("Failed to validate as temporary recipe");

        return isInvalid;
    }

    openTemporaryRecipe = debounce(async (newTemporaryRecipe=null) => {
        let recipe = await this.getTemporaryRecipe();

        if (recipe) {
            if (!(newTemporaryRecipe && newTemporaryRecipe.status !== this.STATUS.TEMPORARY)) return recipe;

            await this.deleteTemporaryRecipe(recipe.id);
            return this.getRecipe(await this.db.recipes.put({
                id: newTemporaryRecipe.id,
                authorName: newTemporaryRecipe.authorName,
                name: newTemporaryRecipe.name,
                status: this.STATUS.TEMPORARY,
                description: newTemporaryRecipe.description,
                completionTime: newTemporaryRecipe.completionTime,
                quantity: newTemporaryRecipe.quantity,
                image: newTemporaryRecipe.image
            }));
        }

        const id = await this.db.recipes.add({
            authorName: 'me',
            name: '',
            status: this.STATUS.TEMPORARY,
            description: '',
            completionTime: 0,
            quantity: 1,
            image: null
        }).catch(error => {
            console.error("Failed to add temporary recipe: ", error);
        });

        return this.getRecipe(id);
    });

    updateRecipe = throttle(async (recipe) => {
        const id = await this.db.recipes.put({
            id: recipe.id,
            authorName: recipe.authorName,
            name: recipe.name,
            status: recipe.status,
            description: recipe.description,
            completionTime: recipe.completionTime,
            quantity: recipe.quantity,
            image: recipe.image
        }).catch(error => {
            console.error("Failed to update temporary recipe: ", error);
        })

        return this.getRecipe(id);
    }, DELAY_LONG)

    updateRecipeIngredients = throttle(async (recipeId, recipeIngredients) => {
        let id = 0;
        const data = recipeIngredients
            .filter(ingredient => ingredient.name !== "")
            .map(ingredient => ({ ...ingredient, id: this._createId(id++, recipeId), recipeId: recipeId }))

        this.db.transaction('rw', this.db.recipeIngredients, async () => {
            await this._deleteAllRecipeIngredients(recipeId);
            await this.db.recipeIngredients.bulkAdd(data);
        }).catch(error => {
            console.error('Failed to update ingredients:', error);
        });
    }, DELAY_LONG)

    updateRecipeSteps = throttle(async (recipeId, recipeSteps) => {
        let id = 0;
        const data = recipeSteps
            .filter(step => step.text !== "")
            .map(step => ({ ...step, id: this._createId(id++, recipeId), recipeId: recipeId }))

        this.db.transaction('rw', this.db.recipeInstructionSteps, async () => {
            await this._deleteAllRecipeSteps(recipeId);
            await this.db.recipeInstructionSteps.bulkAdd(data);
        }).catch(error => {
            console.error('Failed to update steps:', error);
        });
    }, DELAY_LONG)

    async saveTemporaryRecipe(recipeId) {
        if (await this._IsNotTemporaryRecipe(recipeId)) return;

        await this.db.recipes.update(recipeId, {status: this.STATUS.NOT_SYNCED})

        return this.openTemporaryRecipe();
    }

    async deleteTemporaryRecipe(recipeId) {
        if (await this._IsNotTemporaryRecipe(recipeId)) return;

        this.deleteRecipe(recipeId);

        return this.openTemporaryRecipe();
    }

    async deleteRecipe(recipeId) {
        this.db.transaction('rw', this.db.recipeInstructionSteps, this.db.recipeIngredients, this.db.recipes, async () => {
            await this._deleteAllRecipeIngredients(recipeId);
            await this._deleteAllRecipeSteps(recipeId);
            await this._deleteRecipe(recipeId);
        }).catch(error => console.error('Failed to delete temporary recipe', error));
    }
    
    async _deleteAllRecipeIngredients(recipeId) {
        const idsToDelete = await this.db.recipeIngredients.where('recipeId').equals(recipeId).toArray();
        await this.db.recipeIngredients.bulkDelete(idsToDelete.map(record => record.id));
    }

    async _deleteAllRecipeSteps(recipeId) {
        const idsToDelete = await this.db.recipeInstructionSteps.where('recipeId').equals(recipeId).toArray();
        await this.db.recipeInstructionSteps.bulkDelete(idsToDelete.map(record => record.id));
    }

    async _deleteRecipe(recipeId) {
        await this.db.recipes.delete(recipeId);
    }

    _createId(id, recipeId) {
        return `${id}${recipeId}`;
    }
}

const instance = new recipeDB();
export default instance;
