import React, { useEffect, useState, useRef } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { useLocation, useNavigate } from 'react-router-dom';

import recipeDB from "../helpers/localDatabases/recipeDB";
import Svgs from "../components/Svgs";
import { truncate } from "../helpers/stringManipulation";
import ModifiableRecipe from "../components/recipe/ModifiableRecipe";
import Modal from "../components/Modals/Modal";
import EmptyPageModal from "../components/Modals/EmptyPageModal";
import { debounce, DELAY_LONG } from "../helpers/functionHandeler";
import ActionGroup from "../components/Buttons/ActionGroup";
import ShoppingBagModal from "../components/ShoppingBag/ShoppingBagHandler";

export default function RecipeLists() {
    const [recipes, setRecipes] = useState([]);
    const [previewData, setPreviewData] = useState(null);
    const [previewState, setPreviewState] = useState({readonly: true, action: null});
    const ModalRef = useRef(null);
    const ShoppingBagModalRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const cleanText = (text) => text.toLowerCase().replace(' ', '');

        const sortRecipes = (recipeList) => {
            return recipeList.sort((a, b) => cleanText(a.name) - cleanText(b.name));
        }

        const loadRecipes = async (e) => {
            let recipeList = [];

            if (e && e.detail) {
                const filters = e.detail;
                let localRecipes = await getLocalRecipes();
                
                switch(Object.keys(filters).join(',')) {
                    case 'search':
                        localRecipes = localRecipes.filter(recipe => recipe.name.toLowerCase().includes(filters.search.toLowerCase()));
                        break;
                    default:
                        break;
                }

                recipeList = [...localRecipes]; //todo this is local only logic
            }
            else recipeList = await getAllRecipes();

            setRecipes(sortRecipes(recipeList));
        }

        const getAllRecipes = async () => {
            return [...await getLocalRecipes()]; //todo this is local only logic
        }

        const blobUrls = [];

        const getLocalRecipes = async () => {
            const localRecipes = await recipeDB.getRecipes();
    
            localRecipes.map(recipe => {
                if(recipe.image) {
                    const url = URL.createObjectURL(recipe.image);
                    blobUrls.push(url);
                    recipe.image = url;
                }
                
                return recipe;
            })
    
            return localRecipes;
        }

        window.addEventListener("reload", loadRecipes);
        loadRecipes();

        return () => {
            window.removeEventListener("reload", loadRecipes);
            blobUrls.forEach(url => URL.revokeObjectURL(url));
        }
    }, []);

    useEffect(() => {
        if (!location.state) return;
        
        setPreviewData(location.state);
        navigate(location.pathname)
    }, [location.pathname, location.state, navigate])

    async function showDetails(e) {
        if (e.target.closest('.extra-actions')) return;
        setPreviewState({readonly: true, action: null});
        setupPreview(e);
    }

    async function setupPreview(e) {
        const data = await getPreviewData(e)
        setPreviewData(data);
    }

    function getPreviewData(e) {
        const id = parseInt(e.target.closest('.card').dataset.id);
        const location = e.target.closest('.card').dataset.location;

        if (location === 'LOCAL') return getRecipeDataLocal(id);
        else return null;
    }

    async function getRecipeDataLocal(id) {
        return { ...await recipeDB.getRecipe(id), location: "LOCAL" };
    }

    function onPreviewClose(){
        setPreviewState({readonly: true, action: null});
        setPreviewData(null);

        dispatchEvent(new Event("reload"));
    }

    function onEdit(e) {
        setPreviewState({readonly: false, action: 'updating'});
        setupPreview(e);
    }

    async function onDelete(e) {
        ModalRef.current.open({
            question: "Are you sure you want to delete this recipe?", 
            onContinueCallback: async () => {
                const id = parseInt(e.target.closest('.card').dataset.id);
                await recipeDB.deleteRecipe(id);
                dispatchEvent(new Event("reload"));
            }
        })   
    }

    async function onAdd(e) {
        const data = await getPreviewData(e)

        ShoppingBagModalRef.current.open({
            starterQuantity: data.quantity,
            data,
        });
    }

    function checkIfAuthor(data) {
        return data.authorName === 'me';
    }

    function onSearch(e) {
        filter({ search: e.target.value })
    }

    const filter = debounce((data) => dispatchEvent(new CustomEvent("reload", {
        detail: data,
        bubbles: true,
        cancelable: true
    })), DELAY_LONG);

    function calculateScrollHeight(){
        const div = document.getElementById('options-recipe-list');
        const height = div.getBoundingClientRect().height + 'px';
        const {marginTop, marginBottom} = window.getComputedStyle(div);

        return `calc(100svh - ${height} - ${marginTop} - ${marginBottom} - 3.5rem - 2rem)`;
    };

    return (
        <>
            <Modal ref={ModalRef}/>
            {!previewData && <ShoppingBagModal ref={ShoppingBagModalRef}/>}
            <div className="list-of-cards-options" id="options-recipe-list">
                <div className="list-of-cards-options-text search" onChange={onSearch}>
                    <input type="text" placeholder="Search..." name="recipe-search"/>
                    <Svgs name='Search'/>
                </div>
                <div className="list-of-cards-options-text active" onClick={() => {/* TODO */}}>
                    <p>Local</p>
                    <Svgs name='Downloaded'/>
                </div>
            </div>

            <EmptyPageModal message="Found 0 recipes try using less filters" shouldShow={recipes.length < 1}/>

            {previewData && <ModifiableRecipe previewRecipe={previewData} onClose={onPreviewClose} state={previewState} updatePreviewRecipe={(data) => {setPreviewData(data); setPreviewState({readonly: true, action: null})}}/>}
            {recipes.length > 0 && <SimpleBar style={{height: calculateScrollHeight(), paddingBottom: '6rem'}}>
                <div className="list-of-cards" style={{opacity: (previewData) ? 0 : 1}}>
                    {recipes.map((recipe, index) => <div key={index} className="card" data-id={recipe.id} data-location={recipe.location} onClick={showDetails}>
                        <div className="card-image">
                            {recipe.image && <img src={recipe.image} alt={recipe.name} title={recipe.name}/>}
                            {!recipe.image && <div><Svgs name="Explore"/></div>}
                        </div>

                        <div className="card-content">
                            <h2>{recipe.name}</h2>
                            <p>{truncate(recipe.description, 200)}</p>
                        </div>

                        <div className="card-extra-info">
                            <div className="card-extra-info-segment">
                                <p>{recipe.completionTime}</p>
                                <Svgs name='Duration'/>
                            </div>

                            <div className="card-extra-info-segment">
                                <p>{recipe.quantity}</p>
                                <Svgs name='People'/>
                            </div>
                        </div>

                        <ActionGroup className="extra-actions" buttons={[
                            {
                                icon: 'ShoppingBag',
                                onClick: onAdd
                            },
                            {
                                icon: 'Edit',
                                onClick: onEdit,
                                isHidden: !(checkIfAuthor(recipe) || recipe.authorName === "other")
                            },
                            {
                                icon: 'Delete',
                                onClick: onDelete,
                                isDelete: true,
                                isHidden: !(checkIfAuthor(recipe) || recipe.authorName === "other")
                            }
                        ]}/>
                    </div>)}
                </div>
            </SimpleBar>}
        </>
    );
}
