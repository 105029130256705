import React, { useEffect, useRef, useState } from "react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

import recipeDB from "../../helpers/localDatabases/recipeDB";
import ExtendingLists from "./ExtendingLists";
import GrowingTextArea from "../GrowingTextArea";
import ImageSelector from "./ImageSelector";
import Svgs from "../Svgs";
import { inBetween } from "../../helpers/customMath";
import Modal from "../Modals/Modal";
import { KEYS, readFromSession, writeToSession, writeToSessionOptimized } from "../../helpers/browserStorageHandeler";
import ActionGroup from "../Buttons/ActionGroup";
import Pako from "pako";
import { shareUrl } from "../../helpers/dataShare";
import ShoppingBagModal from "../ShoppingBag/ShoppingBagHandler";

export default function ModifiableRecipe({previewRecipe, onClose, state, updatePreviewRecipe}){
    const [recipe, setRecipe] = useState(null);
    const [componentState, setComponentState] = useState(null);
    const [searchParams] = useSearchParams();
    const ModalRef = useRef(null);
    const ShoppingBagModalRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (state && state.readonly) {
            setRecipe({ ...previewRecipe, id: `-${previewRecipe.id}-` });
            setComponentState("preview");
        }
        else if (state && state.action === "updating") {
            writeToSession(KEYS.NEW_RECIPE_VERSION, previewRecipe);
            writeToSession(KEYS.NEW_RECIPE_VERSION_IMAGE, (previewRecipe.image) ? URL.createObjectURL(previewRecipe.image) : null);

            setRecipe({ ...previewRecipe });
            setComponentState("update");
        } else {
            recipeDB.openTemporaryRecipe().then(setRecipe);
            setComponentState("create");
        }
    }, [state, previewRecipe, searchParams])

    useEffect(() => {
        if (!recipe) 
            return;
        else if (componentState === "update") 
            writeToSessionOptimized(KEYS.NEW_RECIPE_VERSION, recipe);
        else if (componentState === "create") 
            recipeDB.updateRecipe(recipe);
    }, [recipe, componentState]);

    useEffect(() => {
        const recipeData = searchParams.get('recipe');
        if (recipeData) importRecipe(recipeData);

        async function importRecipe(recipeData) {
            try {
                const base64 = recipeData.replace(/-/g, '+').replace(/_/g, '/').replace(/!/g, '=');
                const decodedBytes = atob(base64);
                const uint8Array = new Uint8Array(decodedBytes.split('').map(char => char.charCodeAt(0)));
                const decompressedData = Pako.inflate(uint8Array, {to: 'string'});
                recipeData = JSON.parse(decompressedData);

                const importedRecipe = await recipeDB.openTemporaryRecipe();
                importedRecipe.authorName = "other";

                recipeData = { ...recipeData, id: importedRecipe.id, authorName: "other", image: null, status: importedRecipe.status};
    
                await Promise.all([
                    recipeDB.updateRecipe(recipeData),
                    recipeDB.updateRecipeIngredients(recipeData.id, recipeData.recipeIngredients.sort((a, b) => a.index - b.index).map(ingredient => { delete ingredient.index; return ingredient})),
                    recipeDB.updateRecipeSteps(recipeData.id, recipeData.recipeInstructionSteps.sort((a, b) => a.index - b.index).map(step => { delete step.index; return step}))
                ]);

                navigate({
                    pathname: window.location.pathname,
                    search: ''
                });
            } catch (error) {
                console.error('Error importing recipe: ', error);
            }
        }
    }, [searchParams, navigate])

    function updateRecipe(update){
        setRecipe(prevState => ({...prevState, ...update}));
    }

    function handleSend(e) {
        const onContinue = async () => {
            const currentId = recipe.id;
            setRecipe(await recipeDB.saveTemporaryRecipe(recipe.id));
            navigate({pathname: '/lists'}, {state: await recipeDB.getRecipe(currentId)});
        }

        ModalRef.current.open({
            question: "Are you sure you want to publish this recipe?", 
            onContinueCallback: onContinue
        });
    }

    async function handleUpdate(e) {
        ModalRef.current.open({
            question: "Are you sure you want to save your changes?", 
            onContinueCallback: async () => {
                const recipe = readFromSession(KEYS.NEW_RECIPE_VERSION);
                const image = readFromSession(KEYS.NEW_RECIPE_VERSION_IMAGE);
                
                recipe.image = (image) ? await (await fetch(image)).blob() : null;

                await Promise.all([
                    recipeDB.updateRecipe(recipe),
                    recipeDB.updateRecipeIngredients(recipe.id, recipe.recipeIngredients),
                    recipeDB.updateRecipeSteps(recipe.id, recipe.recipeInstructionSteps),
                ]);

                writeToSession(KEYS.NEW_RECIPE_VERSION, {});
                writeToSession(KEYS.NEW_RECIPE_VERSION_IMAGE, null);

                updatePreviewRecipe(recipe);
            }
        });
    }

    function handleTemporaryRecipeDelete(e) {
        ModalRef.current.open({
            question: "Are you sure you want to delete this recipe?", 
            onContinueCallback: async () => setRecipe(await recipeDB.deleteTemporaryRecipe(recipe.id))
        });
    }

    function handleRecipeDelete(e) {
        ModalRef.current.open({
            question: "Are you sure you want to delete this recipe?", 
            onContinueCallback: async () => {
                await recipeDB.deleteRecipe(previewRecipe.id);
                onClose(e);
            }
        });
    }

    function checkValidity(value) {
        return (value) ? '' : 'invalid';
    }

    function getId() {
        if(componentState === "create") return 'modifiableRecipe';
        if(componentState === "preview") return 'viewableRecipe';
        if(componentState === "update") return 'updateableRecipe';
        return 'modifiableRecipe';
    }

    function close(e) {
        if (componentState === "update") {
            ModalRef.current.open({
                question: "Are you sure you want to remove your changes and close this recipe?", 
                onContinueCallback: () => {
                    writeToSession(KEYS.NEW_RECIPE_VERSION, {});
                    writeToSession(KEYS.NEW_RECIPE_VERSION_IMAGE, null);

                    onClose(e);
                }
            })
        } else onClose(e);
    }

    function exportRecipe() {
        const cleanedRecipe = {...recipe};

        delete cleanedRecipe.id;
        delete cleanedRecipe.status;

        cleanedRecipe.authorName = "other";
        delete cleanedRecipe.image;

        cleanedRecipe.recipeIngredients.sort((a, b) => a.id - b.id).map((ingredient, index) => {
            delete ingredient.id;
            delete ingredient.recipeId;
            ingredient.index = index;
            return ingredient;
        });
        
        cleanedRecipe.recipeInstructionSteps.sort((a, b) => a.id - b.id).map((step, index) => {
            delete step.id;
            delete step.recipeId;
            step.index = index;
            return step;
        })
        const compressed = Pako.deflate(JSON.stringify(cleanedRecipe));
        const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(compressed)));
        const data = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '!');

        shareUrl("Share recipe", `${window.location.origin}/recipes/add?recipe=${data}`)
    }

    function updateImage(blob) {
        updateRecipe({image: blob});
        writeToSession(KEYS.NEW_RECIPE_VERSION_IMAGE, (blob) ? URL.createObjectURL(blob) : null);
    }

    function openPreview() {
        ModalRef.current.open({
            question: "Are you sure you want to remove your changes?", 
            onContinueCallback: () => {
                writeToSession(KEYS.NEW_RECIPE_VERSION, {});
                writeToSession(KEYS.NEW_RECIPE_VERSION_IMAGE, null); 

                setRecipe({ ...previewRecipe, id: `-${previewRecipe.id}-` });

                setComponentState("preview");
            }
        })
    }

    function openEdit() {
        writeToSession(KEYS.NEW_RECIPE_VERSION, previewRecipe);
        writeToSession(KEYS.NEW_RECIPE_VERSION_IMAGE, (previewRecipe.image) ? URL.createObjectURL(previewRecipe.image) : null);

        setRecipe({ ...previewRecipe });
        setComponentState("update");
    }

    function addCurrentToShoppingBag() {
        const cleanedRecipe = { ...recipe };
        cleanedRecipe.recipeIngredients = cleanedRecipe.recipeIngredients.filter(ingredient => ingredient.name.length > 1);
        delete cleanedRecipe.image;

        ShoppingBagModalRef.current.open({
            starterQuantity: recipe.quantity,
            data: cleanedRecipe,
        });
    }

    function updateDuration(duration, allowNull = true) {
        if (!duration && !allowNull) duration = 1;
        if (duration || !allowNull) duration = inBetween(duration, 1, 999);

        updateRecipe({completionTime: duration})
    }

    function updateQuantity(quantity, allowNull = true) {
        if (!quantity && !allowNull) quantity = 1;
        if (quantity || !allowNull) quantity = inBetween(quantity, 1, 20);
        updateRecipe({quantity: quantity})
    }

    return (
        <>  
            <Modal ref={ModalRef}/>
            <ShoppingBagModal ref={ShoppingBagModalRef}/>

            {recipe && <div id={getId()}>
                <ActionGroup buttons={[
                    {
                        icon: 'Delete',
                        onClick: handleRecipeDelete,
                        isHidden: componentState !== "preview",
                        isDelete: true
                    },
                    {
                        icon: 'ShoppingBag',
                        onClick: addCurrentToShoppingBag,
                        isHidden: componentState !== "preview"
                    },
                    {
                        icon: 'Send',
                        onClick: handleUpdate,
                        isHidden: componentState !== "update"
                    },
                    {
                        icon: 'Undo',
                        onClick: openPreview,
                        isHidden: componentState !== "update"
                    },
                    {
                        icon: 'Edit',
                        onClick: openEdit,
                        isHidden: componentState !== "preview"
                    },
                    {
                        icon: 'Export',
                        onClick: exportRecipe,
                        isHidden: componentState === "create" || componentState === "update"
                    },
                    {
                        icon: 'Close',
                        onClick: close,
                        isHidden: componentState === "create"
                    }
                ]}/>

                <SimpleBar>
                    <ImageSelector onChange={updateImage} value={recipe.image} readonly={componentState === "preview"}/>
                    
                    <div id="main-info">
                        <input id="name" className={checkValidity(recipe.name)} type="text" placeholder="recipe name..." autoComplete="off" value={recipe.name} readOnly={componentState === "preview"} onChange={(e) => updateRecipe({name: e.target.value})}/>
                        <GrowingTextArea id="description" className={checkValidity(recipe.description)} placeholder="description..." value={recipe.description} readonly={componentState === "preview"} onChange={(e) => updateRecipe({description: e.target.value})}/>

                        <div id="extra-info-about-recipe">
                            <div id="duration-field">
                                <label htmlFor="duration"><Svgs name='Duration'/></label>
                                <input id="duration" type="number" min={1} max={999} placeholder={1} autoComplete="off" value={recipe.completionTime} readOnly={componentState === "preview"} onChange={(e) => updateDuration(e.target.value)} onBlur={(e) => updateDuration(e.target.value, false)}/>
                            </div>

                            <div id="quantity-field">
                                <label htmlFor="quantity"><Svgs name='People'/></label>
                                <input id="quantity" type="number" min={1} max={20} placeholder={1} autoComplete="off" value={recipe.quantity} readOnly={componentState === "preview"} onChange={(e) => updateQuantity(e.target.value)} onBlur={(e) => updateQuantity(e.target.value, false)}/>
                            </div>
                        </div>
                    
                        {recipe && <ExtendingLists recipe={recipe} readonly={componentState === "preview"} updating={componentState === "update"}/>}

                        {componentState === "create" && <div id="recipe-actions">
                            <button className="send" onClick={handleSend}>
                                <Svgs name='Send'/>
                                <p>Publish</p>
                            </button>

                            <button className="delete" onClick={handleTemporaryRecipeDelete}>
                                <Svgs name='Delete'/>
                                <p>Delete</p>
                            </button>
                        </div>}
                    </div>
                </SimpleBar>
            </div>}
        </>
    )
}