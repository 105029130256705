import React, { useEffect, useState } from "react";

import recipeDB from "../../helpers/localDatabases/recipeDB";
import { KEYS, writeToSessionOptimized } from "../../helpers/browserStorageHandeler";

export default function IngredientInputFields({recipe, readonly, className, updating}){
    const [recipeIngredients, setRecipeIngredients] = useState([]);
    const [currentRecipeId, setCurrentRecipeId] = useState(null);

    useEffect(() => {
        if (!(recipe && recipe.id) || recipe.id === currentRecipeId) return;

        setCurrentRecipeId(recipe.id);

        if (readonly || updating) prepareIngredients(recipe.recipeIngredients, readonly);
        else recipeDB.getRecipeIngredients(recipe.id).then(prepareIngredients);

        function prepareIngredients (ingredients, isPreview = false) {
            const cleanedIngredients = ingredients.filter(ingredient => ingredient.name !== '').sort((a, b) => a.id - b.id).map(ingredient => ({ name: ingredient.name, amount: ingredient.amount, unit: ingredient.unit}))
            if (!isPreview && cleanedIngredients.length < 1) cleanedIngredients.push({ name: "" });

            setRecipeIngredients(cleanedIngredients);
        }
    }, [recipe, readonly, currentRecipeId, updating]);

    useEffect(() => {
        if (readonly) return;   
        if (recipeIngredients.length < 1) return;

        if (updating) {
            recipe.recipeIngredients = recipeIngredients;
            writeToSessionOptimized(KEYS.NEW_RECIPE_VERSION, recipe);
        } else recipeDB.updateRecipeIngredients(currentRecipeId, recipeIngredients);

        if (recipeIngredients[recipeIngredients.length - 1].name !== "") setRecipeIngredients(ingredients => [...ingredients, { name: "" }])
    }, [recipe, recipeIngredients, readonly, updating, currentRecipeId]);

    function handleIngredientNameUpdate(index, e) {
        handleIngredientUpdate(index, 'name', e.target.value, e.target.value === e.nativeEvent.data);
    }

    function handleIngredientAmountUpdate(index, e) {
        let value = e.target.value.replace(/[^0-9.]/g, '');

        if(e.nativeEvent.data === '.') {
            if(value.split('.').length > 2) return;

            if(value[0] === '.') {
                const parts = value.split('.');
                value = `0.${parts[1]}`
            } 
        }

        if(value.length > 5) return;

        handleIngredientUpdate(index, 'amount', value)
    }

    function handleIngredientUnitUpdate(index, e) {
        let value = e.target.value.replace(/[^a-zA-Z.]/g, '');

        if(value[value.length - 2] === '.') return;

        if(e.nativeEvent.data === '.') {
            if(value.length < 2) return;
            if(value[value.length - 1] !== '.') return;
        }

        if(value.length > 5) return;

        handleIngredientUpdate(index, 'unit', value.toLowerCase())
    }

    function handleIngredientUpdate(index, key, value, first=false) {
        let newIngredients = [...recipeIngredients];
        newIngredients[index][key] = value;

        if (first){   
            newIngredients[index]['unit'] = "g"
            newIngredients[index]['amount'] = "0"
        }

        setRecipeIngredients(newIngredients);
    }

    function handleIngredientNameBlur(index, e) {
        if (index === recipeIngredients.length - 1 || e.target.value.trim().length !== 0) return;

        setRecipeIngredients(ingredients => {
            const newIngredients = [...ingredients];
            newIngredients.splice(index, 1);
            return newIngredients;
        });
    }

    function handleIngredientAmountBlur(index, e) {
        if(index === recipeIngredients.length - 1 || e.target.value.length > 0) return; 

        e.target.value = "0";
        handleIngredientAmountUpdate(index, e);
    }

    function handleIngredientUnitBlur(index, e) {
        if(index === recipeIngredients.length - 1 || e.target.value.length > 0) return; 

        e.target.value = "g";
        handleIngredientUnitUpdate(index, e);
    }

    function handleLaneNumberBlur(index, e) {
        let newIndex = e.target.value;

        if (newIndex === "") return;
        e.target.value = "";

        newIndex--;

        const max = recipeIngredients.length - 2;
        const min = 0;
        
        if (newIndex < min) newIndex = min;
        else if(newIndex > max) newIndex = max;

        setRecipeIngredients(ingredients => {
            const newIngredients = [...ingredients];
            newIngredients.splice(newIndex, 0, newIngredients.splice(index, 1)[0]);
            return newIngredients;
        });
    }

    function handleLaneNumberKeyDown(e) {
        if (e.keyCode === 13){
            e.preventDefault();
            document.activeElement.blur();
            return;
        }

        const key = e.key;
        const filteredKey = key.replace(/[^0-9]/g, '');

        if (key.length > 1) return;
        if (filteredKey.length < 1 || e.target.value.length > 2) e.preventDefault();
    }

    return (
        <>
            {recipeIngredients.length > 0 && recipeIngredients.map((ingredient, index) => (
                <div className={"ingredient" + ((className) ? " " + className : "")} key={`name-temp-${index}`}>
                    <div className="laneIndicator">
                        <input
                            type="text"
                            name={`laneNumber_${index}`}
                            placeholder={index + 1 + "."}
                            readOnly={readonly}
                            onKeyDown={handleLaneNumberKeyDown}
                            onBlur={(e) => handleLaneNumberBlur(index, e)}
                            inputMode="numeric"
                        />
                    </div>

                    <input
                        type="text"
                        name={`name_${index}`}
                        className="name"
                        autoComplete="off"
                        placeholder="Next ingredient..."
                        value={ingredient.name}
                        readOnly={readonly}
                        onChange={(e) => handleIngredientNameUpdate(index, e)}
                        onBlur={(e) => handleIngredientNameBlur(index, e)}
                    />

                    <input
                        type="text"
                        autoComplete="off"
                        name={`amount_${index}`}
                        className={`amount${(ingredient.amount !== undefined) ? " visible" : ""}`}
                        value={ingredient.amount ?? ''}
                        readOnly={readonly}
                        onChange={(e) => handleIngredientAmountUpdate(index, e)}
                        onBlur={(e) => handleIngredientAmountBlur(index, e)}
                        inputMode="numeric"
                    />

                    <input
                        type="text"
                        autoComplete="off"
                        name={`unit_${index}`}
                        className={`unit${(ingredient.unit !== undefined) ? " visible" : ""}`}
                        value={ingredient.unit ?? ''}
                        readOnly={readonly}
                        onChange={(e) => handleIngredientUnitUpdate(index, e)}
                        onBlur={(e) => handleIngredientUnitBlur(index, e)}
                    />
                </div>
            ))}
        </>
    );
}