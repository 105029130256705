import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Modal from "../Modals/Modal";
import Svgs from "../Svgs";
import { inBetween } from "../../helpers/customMath";
import { KEYS, readFromStorage, writeToStorage } from "../../helpers/browserStorageHandeler";
import { multiplyValues, normalizeValue, tryCombineValues } from "../../helpers/ingredientCalculator";

const ShoppingBagModal = forwardRef(({defaultQuantity = 1}, ref) => {
    const [quantity, setQuantity] = useState(defaultQuantity);
    const [question, setQuestion] = useState(questionBuilder(defaultQuantity));

    const currentQuantity = useRef(defaultQuantity);
    
    const ModalRef = useRef(null);

    useImperativeHandle(ref, () => ({ close, open }));

    useEffect(() => {
        ModalRef.current.updateQuestion(question);
    }, [question]);

    useEffect(() => {
        currentQuantity.current = quantity;
    }, [quantity]);

    function open({starterQuantity = defaultQuantity, data = null, onContinueCallback=null, onCancelCallback=null}) {
        setQuestion(questionBuilder(starterQuantity));
        setQuantity(starterQuantity);

        ModalRef.current.open({
            question,
            onContinueCallback: () => {
                addRecipeToShoppingBag(data, currentQuantity.current);
                if (onContinueCallback) onContinueCallback();
            },
            onCancelCallback: () => {
                if (onCancelCallback) onCancelCallback();
            }
        })
    }

    function close(type) {
        setQuantity(defaultQuantity);
        setQuestion(questionBuilder(defaultQuantity));

        ModalRef.current.close(type)
    }

    function updateQuantity(quantity, allowNull=true) {
        if (!quantity && !allowNull) quantity = 1;
        if (quantity || !allowNull) quantity = inBetween(quantity, 1, 20);

        setQuantity(quantity);
        setQuestion(questionBuilder(quantity));
    }

    function questionBuilder(quantity) {
        return `Would you like to add ${((quantity)) ? quantity : 1} portion${(parseInt(quantity) === 1) ? '' : 's'} to your shopping bag?`
    }

    return (
        <Modal ref={ModalRef}>
            <div id="shopping-bag-modal-quantity">
                <label htmlFor="shopping-bag-modal-quantity-input"><Svgs name='People'/></label>
                <input id="shopping-bag-modal-quantity-input" type="number" min={1} max={20} placeholder={1} autoComplete="off" value={quantity} onChange={(e) => updateQuantity(e.target.value)} onBlur={(e) => updateQuantity(e.target.value, false)}/>
            </div>
        </Modal>
    );
});

function _normalizeIngredientsList(ingredients) {
    let normalizedIngredients;

    ingredients.forEach(ingredient => normalizedIngredients = _normalizeIngredient(ingredient.name, ingredient.value, ingredient.unit, normalizedIngredients));

    return normalizedIngredients;
}

function _normalizeIngredient(name, value, unit, ingredients={}) {
    let copyNumber = 1;

    while(true) {
        const ingredientName = (copyNumber < 2) ? name : `${name}-${copyNumber}`;

        if (!ingredients[ingredientName]) {
            ingredients[ingredientName] = {value, unit};
            break;
        }

        const combinedIngredient = tryCombineValues(ingredients[ingredientName], {value, unit});
        if (combinedIngredient) {
            ingredients[ingredientName] = combinedIngredient;
            break;
        }

        copyNumber++;
    }

    return ingredients
}

function _multiplyIngredients(shoppingBag) {
    Object.values(shoppingBag)
        .forEach(recipe => {
            recipe.ingredients = Object.fromEntries(
                Object.entries(recipe.ingredients).map(([key, ingredient]) => [
                    key, multiplyValues({ value: ingredient.value, unit: ingredient.unit }, recipe.quantity)
                ])
            );
        });

    return shoppingBag;
}

function addRecipeToShoppingBag(recipeData, quantity) {
    let shoppingBag = readFromStorage(KEYS.SHOPPING_BAG) ?? {};

    shoppingBag[recipeData.name] = {
        quantity: quantity,
        baseQuantity: 1,
        location: recipeData.location,
        recipeId: recipeData.id,
        ingredients: _normalizeIngredientsList(recipeData.recipeIngredients.map(ingredient => ({ name: ingredient.name, ...normalizeValue(ingredient.amount / recipeData.quantity, ingredient.unit) })))
    };

    writeToStorage(KEYS.SHOPPING_BAG, shoppingBag)
}

function getShoppingBagPerRecipe() {
    return _multiplyIngredients(readFromStorage(KEYS.SHOPPING_BAG) ?? {});
}

function getShoppingBagPerIngredient() {
    const shoppingBag = getShoppingBagPerRecipe();
    let ingredients;

    Object.values(shoppingBag).forEach(recipe => {
        Object.entries(recipe.ingredients).forEach(([key, value]) => {
            ingredients = _normalizeIngredient(key, value.value, value.unit, ingredients)
        })
    });

    return {"All ingredients": ingredients};
}

function updateQuantity(recipeName, quantity) {
    const shoppingBag = readFromStorage(KEYS.SHOPPING_BAG)
    shoppingBag[recipeName].quantity = quantity;
    writeToStorage(KEYS.SHOPPING_BAG, shoppingBag);
}

function deleteRecipe(recipeName) {
    const shoppingBag = readFromStorage(KEYS.SHOPPING_BAG);

    delete shoppingBag[recipeName];

    writeToStorage(KEYS.SHOPPING_BAG, shoppingBag);
}

function deleteIngredient(recipeName, ingredientName) {
    const shoppingBag = readFromStorage(KEYS.SHOPPING_BAG);

    delete shoppingBag[recipeName].ingredients[ingredientName];

    writeToStorage(KEYS.SHOPPING_BAG, shoppingBag);
}

function deleteAll() {
    writeToStorage(KEYS.SHOPPING_BAG, {});
}

export default ShoppingBagModal;
export { getShoppingBagPerIngredient, getShoppingBagPerRecipe, updateQuantity, deleteIngredient, deleteRecipe, deleteAll }
